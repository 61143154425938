import {LiaHandshakeSolid} from "react-icons/lia";
import {PiGraphLight} from "react-icons/pi";
import {BsGraphUp, BsHeartPulse} from "react-icons/bs"

import img5 from "../assets/yanis.webp"

import img6 from "../assets/noemie-lise.jpg"
import img7 from "../assets/ludovic.webp"

import mezard from "../assets/fabien-mezard.webp"
import sassi from "../assets/hosni-sassi.png"
import vindry from "../assets/dominique-vindry.webp"
import pichard from "../assets/elisabeth-pichard.webp"


export const navLinks = [
    {label: "Accueil", href: "/"},
    {label: "Boutique", href: "/shop"},
    {label: "Sponsoring", href: "/sponsoring"},
    {label: "Nous contacter", href: "/contact"},
]

export const services = [
    {
        icon: <LiaHandshakeSolid/>,
        label: "RESPECT"
    },
    {
        icon: <BsHeartPulse/>,
        label: "PLAISIR"
    },
    {
        icon: <PiGraphLight/>,
        label: "PARTAGE"
    },
    {
        icon: <BsGraphUp/>,
        label: "PROGRÈS"
    }
]


export const client = [
    {
        id: 1,
        desc: '"Travailler avec les jeunes du FCSF est une expérience enrichissante au quotidien. Au-delà du terrain, nous construisons ensemble des valeurs de respect, de solidarité et de dépassement de soi. C\'est une fierté de voir ces jeunes évoluer et s\'épanouir dans un environnement aussi positif et bienveillant."',
        img: img5,
        clientName: "Yanis",
        role: "Éducateur"
    },
    {
        id: 2,
        desc: '"Toujours impressionnée par l\'atmosphère chaleureuse et conviviale qui règne lors des matchs. C\'est un plaisir de partager ma passion pour le football avec d\'autres fans et de soutenir nos équipes avec enthousiasme."',
        img: img6,
        clientName: "Noémie Lise",
        role: "Supportrice"
    },
    {
        id: 3,
        desc: '"Offrir à nos membres la meilleure expérience possible. Cela signifie travailler en équipe, écouter les besoins de chacun et prendre des décisions transparentes et équitables. C\'est un défi passionnant et gratifiant, que nous relevons avec engagement et enthousiasme chaque jour."',
        img: img7,
        clientName: "Ludovic",
        role: "Bénévole"
    },
]

export const teamMembers = [
    {
        img: mezard,
        role: "Président",
        name: "Fabien MEZARD"
    },
    {
        img: sassi,
        role: "Président Délégué",
        name: "Hosni SASSI"
    },
    {
        img: vindry,
        role: "Trésorier",
        name: "Dominique VINDRY"
    },
    {
        img: pichard,
        role: 'Secrétaire Générale',
        name: "Élisabeth PICHARD"
    }
]

export const events = [
    {
        month: "10",
        date: "21/10/2024 - 15/10/2024",
        time: "",
        place: "Stade PDL",
        titre: "Stage Toussaints"
    },
    {
        month: "11",
        date: "08/11/2024",
        time: "19h00",
        place: "Stade PDL",
        titre: "AG du club"
    },
    {
        month: "12",
        date: "18/12/2024",
        time: "",
        place: "Stade PDL",
        titre: "Goûter de Noël école de foot"
    },
    {
        month: "1",
        date: "04/01/2025",
        time: "",
        place: "Stade PDL",
        titre: "Tournois National en salle U10"
    },
    {
        month: "1",
        date: "17/01/2025",
        time: "",
        place: "Stade PDL",
        titre: "Soirée partenaires"
    },
    {
        month: "2",
        date: "24/02/2025 - 28/02/2025",
        time: "",
        place: "Stade PDL",
        titre: "Stage Février"
    },
    {
        month: "4",
        date: "21/04/2025 - 25/04/2025",
        time: "",
        place: "Stade PDL",
        titre: "Stage de Pâques"
    },
    {
        month: "6",
        date: "21/06/2025",
        time: "",
        place: "Stade PDL",
        titre: "Tournoi U11"
    },
    {
        month: "6",
        date: "07/06/2025",
        time: "",
        place: "Stade PDL",
        titre: "Tournoi U7 (pentecôte)"
    },
    {
        month: "6",
        date: "08/06/2025",
        time: "",
        place: "Stade PDL",
        titre: "Tournoi U9 (pentecôte)"
    },
    {
        month: "6",
        date: "21/06/2025",
        time: "",
        place: "Stade PDL",
        titre: "Tournoi U13"
    },
    {
        month: "6",
        date: "22/06/2025",
        time: "",
        place: "Stade PDL",
        titre: "Tournoi caritatif  U13F-U15F"
    },
    {
        month: "7",
        date: "05/07/2025",
        time: "",
        place: "Stade PDL",
        titre: "Fête du club"
    },
]

export const teams = [
    {
        name: "SENIOR M1",
        widget: "667985ee9bd15b08cf62f802"
    },
    {
        name: "SENIOR M2",
        widget: "667987909bd15b08cde34eed"
    },
    {
        name: "U20 M1",
        widget: "667987aa0844ac08ca14c038"
    },
    {
        name: "U20 M2",
        widget: "667987ba9bd15b08cf62f807"
    },
    {
        name: "U18 F1",
        widget: "667987cd0844ac08c46405f3"
    },
    {
        name: "U17 M1",
        widget: "667987e79bd15b08d1b0d351"
    },
    {
        name: "U17 M2",
        widget: "667988290844ac08ca14c03a"
    },
    {
        name: "U17 M3",
        widget: "667988a80844ac08c57b59d4"
    },
    {
        name: "U15 M1",
        widget: "667988f80844ac08c46405f5"
    },
    {
        name: "U15 M2",
        widget: "6679890e0844ac08c57b59d7"
    },
    {
        name: "U15 F1",
        widget: "667989249bd15b08d28aad8c"
    },
    {
        name: "U13 M1",
        widget: "667989320844ac08c57b59d8"
    },
    {
        name: "U13 M2",
        widget: "66798a2d0844ac08c606d911"
    },
    {
        name: "U13 M3",
        widget: "66798a469bd15b08cf62f80b"
    },
    {
        name: "U13 F1",
        widget: "66798a8e0844ac08c46405f9"
    },
    {
        name: "U12 M1",
        widget: "66798a790844ac08ca14c03e"
    },
    {
        name: "VETERAN M1",
        widget: "66798aa00844ac08c606d913"
    },
    {
        name: "LOISIR M1",
        widget: "66798ab30844ac08c606d914"
    },
    {
        name: "LOISIR M2",
        widget: "66798ac09bd15b08cde34ef6"
    },
    {
        name: "LOISIR M3",
        widget: "66798acd9bd15b08d1b0d355"
    }
]

export const blog = [
    {
        img: "https://img.youtube.com/vi/ymuFO-myE_Q/maxresdefault.jpg",
        date: "23 Avril 2024",
        role: "Pieds Carrés",
        desc: "E1 : Retour à Ste FOY pour une opération maintien IMPOSSIBLE (l'équipe est dernière de D1)",
        link: "https://www.youtube.com/watch?v=ymuFO-myE_Q"
    },
    {
        img: "https://img.youtube.com/vi/aZtN8SrO4gY/maxresdefault.jpg",
        date: "20 Mai 2023",
        role: "Pieds Carrés",
        desc: "E28 : Mon DERNIER match à St Foy. Bagarre, record, cartons, il ne faut PAS gâcher la fête",
        link: "https://www.youtube.com/watch?v=aZtN8SrO4gY"
    }
]

export const contact = [
    {
        "role": "Président",
        "name": "Fabien MEZARD",
        "phone": "04 78 59 20 79",
        "mail": "presidentfcstefoy@gmail.com"
    },
    {
        "role": "Président Délégué",
        "name": "Hosnie SASSI",
        "phone": "04 78 59 20 79",
        "mail": "presidentfcstefoy@gmail.com"
    },
    {
        "role": "Trésorier",
        "name": "Dominique VINDRY",
        "phone": "04 78 59 20 79",
        "mail": "secretariatfcstefoy@gmail.com"
    },
    {
        "role": "Secrétaire Générale",
        "name": "Élisabeth PICHARD",
        "phone": "04 78 59 20 79",
        "mail": "secretariatfcstefoy@gmail.com"
    },
    {
        "role": "Coordinateur Foot A11",
        "name": "Kamel SADOUNE",
        "phone": "06 99 64 52 24",
        "mail": null
    },
    {
        "role": "Coordinateur Ecole de Foot",
        "name": "Guillaume PULLARA",
        "phone": "06 11 49 24 31",
        "mail": null
    },
    {
        "role": "Coordinateur Foot Féminin",
        "name": "Sylvain CHÂTEAUNEUF",
        "phone": "0609953741",
        "mail": "syl.chato9@gmail.com"
    },
    {
        "role": "Coordinateur Foot  Loisir",
        "name": "Jean-Louis PONTONE",
        "phone": "0787255427",
        "mail": "ptitlouis.foot@yahoo.com"
    },
    {
        "role": "Coordinateur Foot Adapté",
        "name": "Giani OLLON",
        "phone": "06.59.65.51.33",
        "mail": "gianiho69780@hotmail.fr"
    },
    {
        "role": "Coordinateur Stage & Tournois",
        "name": "Rémy PELUX",
        "phone": "0658679144",
        "mail": "reruco@hotmail.fr"
    }
]

export const footer = [
    {
        title: "LIENS",
        footerLinks: [
            {label: "Accueil", href: "/"},
            {label: "Boutique", href: "/shop"},
            {label: "Galerie Photo", href: "/photo"},
            {label: "Sponsoring", href: "/sponsoring"},
            {label: "Nous contacter", href: "/contact"},
        ]
    },
    {
        title: "PARTENAIRES",
        footerLinks: [
            {label: "OL", href: "https://www.ol.fr/fr"},
            {label: "Crédit Mutuel", href: "https://www.creditmutuel.fr/"},
            {label: "Sainte-Foy-lès-Lyon Ville", href: "https://www.saintefoyleslyon.fr/"},
            {label: "ADIDAS TEAM", href: "https://www.adidasteam.com/fr-fr/clubshop/f-c-ste-foy-les-lyon/"},
        ]
    }
]