import React, { useState } from 'react'
import logo from "../assets/logo.png"
import logo1 from "../assets/logo1.png"
import { navLinks } from '../Data/Data'
import {FiMenu} from "react-icons/fi"
import {IoClose} from "react-icons/io5"
import {Link} from "react-router-dom";

const Nav = ({nav}) => {

  const [openNav,setOpenNav] = useState(false)

  return (
    <header className={`w-full ${nav ? "fixed bg-black duration-300 top-0 left-0 z-30" : "absolute"}`}>
      <nav
          className='max-w-[1540px] m-auto py-6 px-6 max-lg:px-12 flex justify-between items-center gap-14 max-xl:gap-5 max-sm:py-4 max-sm:px-6'>
        <Link to="/" className=' font-semibold text-5xl flex justify-center items-center text-white gap-1'>
          <img src={logo1} alt="logo" width={80}/>
          <img src={logo} alt="logo" width={160}/>
        </Link>

        <div
            className={`lg:hidden text-2xl text-white cursor-pointer`}
            onClick={() => setOpenNav(!openNav)}>
          {openNav ? <IoClose/> : <FiMenu/>}
        </div>
        <ul className={`${openNav ? "max-lg:block" : "max-lg:hidden"} flex flex-1 justify-end gap-10 max-xl:gap-7 max-lg:flex-col max-lg:bg-black max-lg:text-white max-lg:pt-10 max-lg:pb-3 max-lg:px-4 max-lg:gap-4 max-lg:absolute max-lg: max-lg:top-full max-lg:left-[5%] max-lg:w-[90%] max-lg:z-20 max-sm:top-20`}>
          {navLinks.map((val) => (
              <li key={val.label}
                  className={`text-white font-medium hover:text-red-500 text-xl max-lg:text-lg max-lg:block`}>
                <Link to={val.href} target={val.target} >{val.label}</Link></li>
          ))}
        </ul>

        <Link to={"equipes"} className=' py-4 px-7 text-xl group relative text-white bg-[#d73523] rounded-sm max-lg:hidden font-semibold'>
          <div className=' buttonDiv'></div>
          <span className='buttonSpan'>NOS ÉQUIPES</span>
        </Link>
      </nav>
    </header>
  )
}

export default Nav