import React from 'react'

import {AiOutlineArrowRight} from "react-icons/ai"
import {Link} from "react-router-dom";

const Gallery = () => {
  return (
    <section id='gallery' className=' min-h-screen w-full grid grid-cols-4 grid-rows-2 gap-7 max-lg:grid-cols-2 max-lg:grid-rows-4 max-lg:gap-4 max-sm:grid-cols-1 max-sm:grid-rows-5 max-sm:gap-x-0'>

      <div className=' group h-screen row-span-2 col-span-2 max-lg:row-span-2 max-lg:h-[49vh] relative bg-gallery-pattern1 bg-cover bg-center max-sm:row-span-1 max-sm:col-span-1'></div>
      <div className=' group h-full w-full relative overflow-hidden bg-gallery-pattern2 bg-cover bg-center'></div>
      <div className='group h-full w-full relative overflow-hidden bg-gallery-pattern3 bg-cover bg-center'></div>
      <div className='group  h-full w-full relative overflow-hidden bg-gallery-pattern4 bg-cover bg-center'></div>
      <div className='group  h-full w-full relative overflow-hidden bg-gallery-pattern5 bg-cover bg-center'></div>
    </section>
  )
}

export default Gallery