import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";
import {IoFootball, IoPerson} from "react-icons/io5";
import {FaHandsHelping} from "react-icons/fa";

export default function AccordionBasicExample() {
    const [activeElement, setActiveElement] = useState("");

    const handleClick = (value) => {
        if (value === activeElement) {
            setActiveElement("");
        } else {
            setActiveElement(value);
        }
    };
    return (
        <>
            <div id="accordionExample">
                <div className="rounded-t-lg border border-neutral-200 bg-white">
                    <h2 className="mb-0" id="headingOne">
                        <button
                            className={`${
                                activeElement === "element1" &&
                                `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)]`
                            } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none text-xl`}
                            type="button"
                            onClick={() => handleClick("element1")}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            <span className={"flex flex-row"}><IoPerson className={"mr-3"} /> Comment devenir joueur ?</span>
                            <span
                                className={`${
                                    activeElement === "element1"
                                        ? `rotate-[-180deg] -mr-1`
                                        : `rotate-0 fill-[#212529]`
                                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none`}
                            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
                        </button>
                    </h2>
                    <TECollapse
                        show={activeElement === "element1"}
                        className="!mt-0 !rounded-b-none !shadow-none"
                    >
                        <p className="px-5 py-4 font text-lg text-slate-800 text-justify">
                            Pour rejoindre le FC Sainte Foy Lès Lyon, contactez directement nos coordinateurs pour organiser une détection. Pour le foot à 11, contactez Kamel SADOUNE au <a href={"tel:0699645224"}>06 99 64 52 24</a>. Pour le foot animation et l'école de foot, contactez Guillaume PULLARA au 06 11 49 24 31. Nous serons ravis de vous accueillir pour une session de détection !
                        </p>
                    </TECollapse>
                </div>
                <div className="border border-t-0 border-neutral-200 bg-white">
                    <h2 className="mb-0" id="headingOne">
                        <button
                            className={`${
                                activeElement === "element2" &&
                                `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)]`
                            } group relative flex w-full items-center border-0 bg-white px-5 py-4 text-left text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none text-xl`}
                            type="button"
                            onClick={() => handleClick("element2")}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            <span className={"flex flex-row"}><IoFootball className={"mr-3"} /> Comment devenir éducateur ?</span>
                            <span
                                className={`${
                                    activeElement === "element2"
                                        ? `rotate-[-180deg] -mr-1`
                                        : `rotate-0 fill-[#212529]`
                                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none`}
                            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
                        </button>
                    </h2>
                    <TECollapse
                        show={activeElement === "element2"}
                        className="!mt-0 !rounded-b-none !shadow-none"
                    >
                        <p className="px-5 py-4 font text-lg text-slate-800 text-justify">
                            Pour devenir éducateur au  FC Sainte Foy Lès Lyon, contactez directement notre président, Fabien MEZARD. Vous pouvez le joindre par email à <a href={"mailto:presidentfcstefoy@gmail.com"}>presidentfcstefoy@gmail.com</a> ou par téléphone au <a href={"tel:0478592079"}>04 78 59 20 79</a>. Nous sommes toujours à la recherche de passionnés pour rejoindre notre équipe d'éducateurs !
                        </p>
                    </TECollapse>
                </div>
                <div className="border border-t-0 border-neutral-200 bg-white">
                    <h2 className="mb-0" id="headingOne">
                        <button
                            className={`${
                                activeElement === "element3" &&
                                `text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)]`
                            } group relative flex w-full items-center border-0 bg-white px-5 py-4 text-left text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none text-xl`}
                            type="button"
                            onClick={() => handleClick("element3")}
                            aria-expanded="true"
                            aria-controls="collapseOne"
                        >
                            <span className={"flex flex-row"}><FaHandsHelping className={"mr-3"} /> Comment devenir bénévole ?</span>
                            <span
                                className={`${
                                    activeElement === "element3"
                                        ? `rotate-[-180deg] -mr-1`
                                        : `rotate-0 fill-[#212529]`
                                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none`}
                            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
                        </button>
                    </h2>
                    <TECollapse
                        show={activeElement === "element3"}
                        className="!mt-0 !rounded-b-none !shadow-none"
                    >
                        <p className="px-5 py-4 font text-lg text-slate-800 text-justify">
                            Pour devenir bénévole au FC Sainte Foy Lès Lyon, contactez directement notre président, Fabien MEZARD. Vous pouvez le joindre par email à <a href={"mailto:presidentfcstefoy@gmail.com"}>presidentfcstefoy@gmail.com</a> ou
                            par téléphone au <a href={"tel:0478592079"}>04 78 59 20 79</a>. Votre aide est précieuse
                            pour soutenir notre club et nos activités !
                        </p>
                    </TECollapse>
                </div>
            </div>

        </>
    );
}